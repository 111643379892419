export const environment = {
  production: true,
  locationURL: 'https://integration.gofrugal.com/',
  ecomURL: 'https://integration.gofrugal.com/ecommerce/',
  ondcURL: 'https://integration.gofrugal.com/ondc/',
  accountURL: 'https://integration.gofrugal.com/accounts/',
  paymentURL: 'https://integration.gofrugal.com/payment/',
  smartReports: 'https://integration.gofrugal.com/reports/#/reports?',
  srDashboard: 'https://integration.gofrugal.com/reports/#/dashBoard?',
  storePurchase: 'https://store.gofrugal.com/service_index.php?',
  link742: 'https://ordereasy.gofrugal.com/',
  link538: 'https://godeliver.gofrugal.com/',
  link744: 'https://gotracker.gofrugal.com/',
  faURL: 'https://integration.gofrugal.com/fa/',
  loyaltyURL: 'https://alert.gofrugal.com/Loyalty/'
};
