import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {AuthGuard} from './core/gaurds/auth.guard';
import {AppRoutingModule} from './app-routing.module';
import {CrmAPIService} from './core/http/crm/crm-api.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NotificationService} from './core/services/notification.service';
import {EcommerceInterceptor} from './core/interceptor/ecommerce.interceptor';
import {LogoutComponent} from './modules/logout/logout.component';
import {SharedService} from './shared/service/shared.service';
import {UiCommonService} from './shared/service/uiCommon.service';
import {StompService} from './core/web-socket/StompService';
import {LiveTrackingStompService} from './core/web-socket/LiveTrackingStompService';
import {HomePageComponent} from './modules/home-page/home-page.component';
import {CardModule} from 'primeng/card';
import {CookieService} from 'ngx-cookie-service';
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from './core/interceptor/timeout.interceptor';
import {PdfViewerComponent} from './modules/pdf-viewer/pdf-viewer.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ReactiveFormsModule} from "@angular/forms";
import { NgxImageCompressService } from 'ngx-image-compress';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DateFormat } from './core/services/date.format';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    HomePageComponent,
    PdfViewerComponent,
    DateFormat,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    // AngularFontAwesomeModule,
    CardModule,
    PdfViewerModule,
    // AngularEditorModule,
    ReactiveFormsModule,
    CarouselModule,
  ],
  // exports : [ AngularEditorModule],
  providers: [
    AuthGuard,
    CrmAPIService,
    HttpClient,
    NotificationService,
    SharedService,
    UiCommonService,
    StompService,
    LiveTrackingStompService,
    CookieService,
    NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EcommerceInterceptor,
      multi: true
    },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 15000 }],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
