import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { AccountApiService } from "../../core/http/account/account-api.service";
import { AuthenticationService } from '../../core/services/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private accountApiService: AccountApiService,
    private authenticationService: AuthenticationService,
  ) { }

  public login(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('security-check', new HttpParams(), header, null, true);
  }

  public serviceUserLogin(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('sul/security-check', new HttpParams(), header, null, true);
  }

  public triggerOtp(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('security-check', new HttpParams(), header, null, true);
  }

  public restPassword(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('reset-password', new HttpParams(), header, null, true);
  }

  public introduceCaptcha(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('introduce-captcha', new HttpParams(), header, null, true);
  }

  // Get Role master 
  public getRoleMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('get-role', new HttpParams(), header, body, true);
  }

  // Get Active add on details 
  public getActiveAddOns(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('active-addon', new HttpParams(), header, body, true);
  }

  // Get Dynamic menu based on add on
  public getDynamicMenu(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('get-menu', new HttpParams(), header, body, true);
  }

  // Save role master 
  public postRoleMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('role-master', new HttpParams(), header, body, true);
  }

  // Get Employee master 
  public getEmployeeMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('get/v1/users', new HttpParams(), header, body, true);
  }

  // Save employee master 
  public postEmployeeMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('user', new HttpParams(), header, body, true);
  }

  // Get list of add on based on email id
  public getAddOnForHomePage(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountApiService.post('addon-details', new HttpParams(), header, body, true);
  }

  // Get Dynamic menu based on add on from home page
  public getDynamicMenuFromHomePage(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountApiService.post('get/user-wise/menu', new HttpParams(), header, body, false);
  }

  //Get AddOn list for EmployeeMaster
  public getAddOnList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountApiService.post('active-addons', new HttpParams(), header, body, true);
  }

  //Get Outlet list for EmployeeMaster
  public getOutletList(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountApiService.post('get/v1/outlets', new HttpParams(), header, body, true);
  }

  public manageSubscriptionLicenseSync(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('license-sync', param, header, body, true);
  }

  public currentPlanDetails(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    return this.accountApiService.post('current-plan', new HttpParams(), header, body, true);
  }

  public trialPlanChange(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('update/trial-license', param, header, body, true);
  }

  public getOrderEasyAddons(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken()).set('key', window.btoa(this.authenticationService.getEmailId()));
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('get/addons', param, header, body, true);
  }

  public getUserDetail(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('users', new HttpParams(), header, null, true);
  }

  public getIpAddress(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    return this.accountApiService.getIpAddress(header);
  }

  public setNewPassword(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('save-users', new HttpParams(), header, null, true);
  }

  public getNotification(body): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/get-portal-notification', new HttpParams(), header, body, true);
  }

  public updateNotification(body): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/update-portal-notification', param, header, body, true);
  }

  public executeQuery(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json').set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.accountApiService.post('run-sql', param, header, body, true);
  }

  public submitFeedBackDetails(formObj: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/report-iot', null, header, formObj, true );
  }

  public getAnnouncementDetails(body): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/get-announcement', null, header, body, true );
  }

  public getAnnouncementRead(body): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    return this.accountApiService.post('v1/announcement-read', null, header, body, true );
  }

  public adminUserLogin(key: string): Observable<any> {
    const header = new HttpHeaders().set('key', key);
    return this.accountApiService.post('ondc/security-check', new HttpParams(), header, null, true);
  }
}
